<template>
  <div class="mt-10 px-4">
    <!-- background -->
    <div class="background-ball-big"></div>
    <div class="background-ball-small"></div>

    <div class="pt-4 title">
      <h3>Create Account</h3>
    </div>

    <template v-if="!layouts.actives.checkEmail">
      <v-form ref="form" @submit.prevent>
        <v-text-field
          v-model="dataSources.current.email"
          label="Enter Email"
          :rules="dataSources.current.emailRule"
        ></v-text-field>
      </v-form>

      <wap-btn class="mt-4 width-full" @click="clickContinue">
        Continue
      </wap-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Already have an account?
        <a class="f-w-600" @click="$router.push('/sign-in')">Sign In</a>
      </div>
    </template>

    <!-- text -->
    <div v-else class="check-text">
      Check your email for a link to set your password. <span class="text-red">If it doesn't appear
      within a few minutes, check your spam folder.</span>
      <br />
      <div class="f-s-16 text-center">
        <span class="text-decoration-underline" :style="dataSources.current.countdown> 0 ? 'color: grey' : ''" @click="onClickSentence">
          Didn't receive the email?
        </span>
        <span class="text-red" v-if="dataSources.current.countdown > 0"> ({{ dataSources.current.countdown }}s)</span>
      </div>
    </div>

    <wap-bottom-sheet
      v-model="layouts.resendSheet"
      :persistent="true"
      title="Didn't receive the email?"
      :notification-sheet="true"
      @confirm="layouts.resendSheet=false"
    >
      <v-card width="100%" flat class="px-5 d-flex justify-center text-center">
        <v-card @click="clickResend" rounded class="px-4">
          <v-icon size="45" color="var(--theme_secondary)">refresh</v-icon>
          <p class="f-s-16 f-w-600">Resend Email</p>
        </v-card>
      </v-card>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import WapBtn from '../../components/base/WapBtn.vue'
import { AccountApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'
import WapBottomSheet from '@/components/base/WapBottomSheet'

export default {
  components: { WapBtn, WapBottomSheet },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      timer: null,
      dataSources: {
        current: {
          email: '',
          countdown: 300,
          emailRule: [v => DataType.ValidationRules.EMAIL.test(v) || 'Must be a valid email.']
        }
      },
      layouts: {
        actives: {
          checkEmail: false
        },
        resendSheet: false
      }
    }
  },
  created () {
    this.countDown(this.layouts.actives.checkEmail)
  },
  methods: {
    countDown (val) {
      if (val) {
        this.dataSources.current.countdown = 300
        this.timer = setInterval(() => {
          if (this.dataSources.current.countdown > 0) {
            this.dataSources.current.countdown--
          } else {
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    clickContinue () {
      this.$refs.form.validate()
      if (this.$refs.form.validate()) {
        this.sendEmail()
      }
    },
    sendEmail () {
      const email = this.dataSources.current.email
      AccountApi.sendCreateAccountEmail({
        portfolioId: this.$store.getters.getPortfolioId,
        email
      }, () => {
        this.layouts.actives.checkEmail = true
        this.layouts.resendSheet = false
        this.countDown(this.layouts.actives.checkEmail)
      }, (err) => {
        this.$store.commit('setPopupInformation', {
          message: err.message
        })
      })
    },
    onClickSentence () {
      const countdown = this.dataSources.current.countdown
      if (countdown > 0) {
        return
      }
      this.layouts.resendSheet = true
    },
    clickResend () {
      this.sendEmail()
    }
  }
}
</script>

<style lang='sass' scoped>
.background-ball-big
  position: absolute
  top: -60vw
  left: -5vw
  width: 110vw
  height: 110vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.background-ball-small
  position: absolute
  top: -55vw
  right: -58vw
  width: 100vw
  height: 100vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.title
  height: 23vh

.check-text
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 90%
  font-size: 17px
</style>
